import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "name" ]

  switch() {

    let on_elements = document.getElementsByClassName('toggled-off');
    let off_elements = document.getElementsByClassName('toggled-on');

    let n_on_elements = on_elements.length;
     for (var i = 0; i < n_on_elements; i++) {
       let e = on_elements[i];

       if(e.style.display == 'block') {
         e.style.display = 'none';
       } else {
         e.style.display = 'block';
       }
    }

    let n_off_elements = off_elements.length;
     for (var i = 0; i < n_off_elements; i++) {
       let e = off_elements[i];

       if(e.style.display == 'none') {
         e.style.display = 'block';
       } else {
         e.style.display = 'none';
       }
    }


    // console.log(`Hello, ${this.name}!`)
  }

  // get name() {
  //   return this.nameTarget.value
  // }
}
